import React from 'react';
import { Link } from 'react-router-dom';
import './diversity.css';
import exchange from '../../images/exchange.jpeg';

function Diversity() {
  return (
    <div className="diversity">
      <div className='diversity-home'>
        <div className="diversity-cover">
          <h1>DIVERSITY AND INCLUSION</h1>
            <div className="ballet-desc">
              <img src="" alt="" />
                <p>We believe that diversity enriches the creative process, and we welcome everybody from all backgrounds, cultures, and abilities to participate in our programs. Our inclusive approach ensures that everyone, regardless of their unique identity or experience, has the opportunity to explore their talents, grow in confidence, and develop a love for the arts. </p>
            </div>
            <div className="ballet-right-desc">
                <p><b>Inclusion</b> is at the heart of everything we do. We work diligently to provide a safe and supportive space where everybody can collaborate, share their stories by promoting empathy, understanding, and teamwork. We prepare our trainees not only to excel in dance and the arts but also to thrive as compassionate, open-minded individuals in a diverse world.  </p>
            </div>
        </div>
      </div>
      <div className="diversity-desc">
        <div className="diversity-top-desc">
          <img src={exchange} alt="exchange program" />
          <div className="desc">
            <p>
            "We are dedicated to creating an environment where everyone is valued, respected, and empowered to express themselves. We embrace individuals of all cultures, backgrounds, religions, races, and abilities."
          </p>
          <Link to="/"><button className='learn-more'>Back Home</button></Link>
          </div>
        </div>
          {/* <div className="diversity-right-desc">
            <p>At Hymnlets Dance & Arts Academy, we are committed to fostering an environment where everyone feels valued, respected, and empowered to express themselves. We believe that <b>diversity</b> enriches the creative process, and we welcome children from all backgrounds, cultures, and abilities to participate in our programs. Our inclusive approach ensures that each child, regardless of their unique identity or experience, has the opportunity to explore their talents, grow in confidence, and develop a love for the arts.   </p>
              <p></p>
          </div> */}
      </div>
    </div>
  )
}

export default Diversity;
