import React from 'react';
import './ksl.css';
import { Link } from 'react-router-dom';
import ksl from '../../images/sign-language.jpg';

function Ksl() {
    // const navigate = useNavigate();
    // const handleNavigate = () => {
    //   navigate('/');
    // }
  return (
    <div className='training'>
            <div className="training-cover">
              <h1>Kenya Sign Language</h1>
              {/* data-aos="slide-right" */}
              <div className="row">
                <div className="col">
                  <img src={ksl} alt="Sign Language" />
                </div>
                <div className="col">
                {/* data-aos="flip-left" */}
                  <p>
                  At Hymnlets Dance & Art Academy, we are proud to offer Kenya Sign Language (KSL) classes as part of our inclusive learning approach. </p>
                  <p>
                  Our program is designed to empower learners with the skills to communicate effectively with the Deaf community, fostering inclusivity, cultural awareness, and mutual understanding. Whether you're a beginner or looking to refine your signing skills, our KSL classes are tailored to all age groups, making meaningful communication accessible to everyone.
                  </p>

              <Link to="/"><button className='learn-more'>Back Home</button></Link>
                </div>
              </div>
            </div>
    </div>
  )
}

export default Ksl;